import React, { Component } from 'react'
import './App.css'

export default class App extends Component {
  constructor() {
    super()
    this.state = {
      links: [],
      link: '',
      size: 4
    }
  }

  changeLink = (event) => this.setState({link: event.target.value})

  tubeRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/

  addLink = (event) => {
    if (event.keyCode !== 13) return
    this.setState({link: ''})

    const match = event.target.value.match(this.tubeRegex)
    if (!match) return

    const id = match[1]
    if (!id) return

    if (!this.state.links.includes(id)) this.setState({
      links: [...this.state.links, id],
    })
  }

  removeLink = (l) => this.setState({links: this.state.links.filter(link => link !== l)})

  sizeChange = (event) => {
    if (event.target.value < this.state.links.length) this.setState({
      links: this.state.links.slice(0, event.target.value)
    })
    this.setState({size: event.target.value})
  }

  render() {
    return (
      <div className="App">
        <div className={`grid grid-${this.state.size}`}>
          {this.state.links.map(l =>
            <div key={l} className={`embedcontainer embedcontainer-${this.state.size}`}>
              <button className={'overlay'} title={'Remove this embed.'} onClick={() => this.removeLink(l)}>
                <strong>X</strong>
              </button>
              <iframe
                key={l}
                title={l}
                className="youtube-player"
                src={`//www.youtube-nocookie.com/embed/${l}?autoplay=1&amp;modestbranding=1`}
                allowFullScreen="true"
                frameBorder="0"
              />
            </div>
          )}
          {this.state.links.length < this.state.size && <div>
            <div>
              <label>Paste link: </label>
              <input value={this.state.link} onChange={this.changeLink} onKeyUp={this.addLink}/>
            </div>
            <div>
              <label>Grid size: </label>
              <select name={'size'} value={this.state.size} onChange={this.sizeChange}>
                <option value='2'>2</option>
                <option value='4'>4</option>
                <option value='6'>6</option>
                <option value='9'>9</option>
              </select>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}
